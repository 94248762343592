<template>
	<div class="adminlogin">
		<div class="adminlogin_head">
			<img src="@/assets/logo_makou.png" alt="" srcset="" />
			<span style="margin-left:10px;font-size: 20px;color: #1e2226 !important; font-weight: 600 !important;">盛夏光年</span>
		</div>
		<div class="adminlogin_box">
			<div :style="{'minHeight':minheight}" class="adminlogin_form">
				<slot></slot>
			</div>
		</div>
		<div class="adminlogin_footer">
			<p>Copyright © 2019-2024 All Rights Reserved</br>成都盛夏光年数字科技有限公司版权所有 蜀ICP备2024045106号</p>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			minheight:{
				type:String,
				default:'520px'
			}
		},
	}
</script>

<style lang="scss" scoped>
	.adminlogin {
		
		background-image:linear-gradient(#edf5fe, #e2edfe);
		&_head {
			height: 50px;
			line-height: 50px;
			width: 100%;
			background-color: #f4f8fe;
			display: flex;
			align-items: center;
			box-shadow: 0 2px 20px 0 rgba(140, 147, 161, .07);
			position: relative;
			z-index: 9999999;
			img {
				height: 39px;
				margin-left: 28px;
			}
		}
	
		&_box {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			// margin-top: -50px;
			min-height: calc(100vh - 50px);
		}
		&_form{
			width: 420px;
			padding: 40px 40px 30px;
			// min-height: 520px;
			margin: -50px auto 0 auto;
			border: 1px solid #fff;
			border-radius: 10px;
			background-color: #fff;
			box-shadow: 0 20px 30px 0 rgb(63 63 65/6%);
			position: relative;
		}
	
		&_footer {
			position: fixed;
			bottom: 0;
			height: 60px;
			text-align: center;
			width: 100%;
			z-index: 0;
			p {
				margin-bottom: 12px;
				font-size: 12px;
				color: #9598a6;
			}
		}
	}
</style>